<template>
  <div class="w-full rounded-md border bg-white overflow-hidden" v-loading="true" style="height: 90vh"></div>
</template>

<script>
export default {
  data() {
    return {};
  },
  name: 'FbMessengerIntegrationOauthHandler',
  methods: {},

  async mounted() {
    let code = this.$route.query.code;
    let state = this.$route.query.state;

    if (!state || !code) {
      return;
    }

    // Pass parent to process.
    window.opener.postMessage(
      {
        growlytics: 1,
        action: 'fb-messenger-oauth-response',
        data: this.$route.query
      },
      '*'
    );

    window.close();
  }
};
</script>

<style></style>
